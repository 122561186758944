import {
  BAD_SENTIMENT_GAUGE_COLOR,
  GOOD_SENTIMENT_GAUGE_COLOR,
  INPUT_COLOR,
  LINK_COLOR,
  MAIN_COLOR,
  MAIN_COMPONENT_COLOR,
  MAIN_TEXT_COLOR,
  MID_SENTIMENT_GAUGE_COLOR,
  SECONDARY_TEXT_COLOR,
} from "../constants/colors";
import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import DataTable, { TableColumn } from "react-data-table-component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import {
  faAngleLeft,
  faAngleRight,
  faAnglesLeft,
  faAnglesRight,
} from "@fortawesome/free-solid-svg-icons";
import { ENDPOINTS_ADDRESS } from "../constants/ip_address";
import ProgressBar from "react-bootstrap/esm/ProgressBar";

type TrendData = {
  id: number;
  rank: string;
  cryptoName: string;
  sentiment: string;
};

function SentimentGauge(props: { sentiment: number }) {
  if (!Number.isNaN(props.sentiment)) {
    if (props.sentiment >= 7) {
      return (
        <SentimentGaugeWrapper>
          <GoodSentimentGauge
            now={props.sentiment * 10}
            label={`${props.sentiment}/10`}
          />
        </SentimentGaugeWrapper>
      );
    }
    if (props.sentiment < 7 && props.sentiment >= 4) {
      return (
        <SentimentGaugeWrapper>
          <MidSentimentGauge
            now={props.sentiment * 10}
            label={`${props.sentiment}/10`}
          />
        </SentimentGaugeWrapper>
      );
    }
    if (props.sentiment < 4 && props.sentiment > 0) {
      return (
        <SentimentGaugeWrapper>
          <BadSentimentGauge
            now={props.sentiment * 10}
            label={`${props.sentiment}/10`}
          />
        </SentimentGaugeWrapper>
      );
    }
    if (props.sentiment === 0) {
      return (
        <SentimentGaugeWrapper>
          <BadSentimentGauge now={100} label={`${props.sentiment}/10`} />
        </SentimentGaugeWrapper>
      );
    }
  }
  return "----";
}

export function TrendsTable(props: { className?: string }) {
  const navigate = useNavigate();

  const [data, setData] = useState([] as TrendData[]);
  //   {
  //     id: 0,
  //     rank: 1,
  //     token_name: "Shopping List",
  //     sentiment: 8,
  //   },
  //   {
  //     id: 0,
  //     rank: 1,
  //     token_name: "Shopping List",
  //     sentiment: 8,
  //   },
  //   {
  //     id: 0,
  //     rank: 1,
  //     token_name: "Shopping List",
  //     sentiment: 8,
  //   },
  //   {
  //     id: 0,
  //     rank: 1,
  //     token_name: "Shopping List",
  //     sentiment: 8,
  //   },
  //   {
  //     id: 0,
  //     rank: 1,
  //     token_name: "Shopping List",
  //     sentiment: 8,
  //   },
  //   // Additional rows...
  // ];

  // const data = { nodes };

  // const pagination = usePagination(
  //   data,
  //   {
  //     state: {
  //       page: 0,
  //       size: 2,
  //     },
  //   },
  //   {
  //     isServer: true,
  //   }
  // );

  // const theme = useTheme({
  //   Table: ``,
  //   HeaderRow: `.th {
  //     background-color: ${MAIN_COMPONENT_COLOR};
  //     color: ${MAIN_TEXT_COLOR};
  //     font-size: 14px;
  //   }`,
  //   Row: `
  //         .td {
  //           background-color: ${MAIN_COLOR};
  //           color: ${MAIN_TEXT_COLOR};
  //           font-size: 12px;
  //         }
  //     `,
  // });

  const columns: TableColumn<TrendData>[] = [
    {
      name: "Popularity Ranking",
      selector: (row) => row.rank,
      sortable: true,
    },
    {
      name: "Token",
      selector: (row) => row.cryptoName,
      sortable: true,
      sortFunction: (a, b) => a.cryptoName.localeCompare(b.cryptoName),
    },
    {
      name: "Sentiment",
      cell: (row) => (
        <SentimentGauge sentiment={Number.parseInt(row.sentiment)} />
      ),
      selector: (row) => row.sentiment,
      sortable: true,
    },
  ];

  useEffect(() => {
    (async () => {
      const queriedData: TrendData[] = await fetch(
        `${ENDPOINTS_ADDRESS}/getTrendData`
      ).then((response) => response.json());
      let sortedQueriedData = queriedData.sort((a, b) => {
        if (a.rank > b.rank) return 1;
        if (b.rank > a.rank) return -1;
        return 0;
      });

      if (sortedQueriedData.length % 3 === 1) {
        sortedQueriedData = [
          ...sortedQueriedData,
          {
            id: -1,
            rank: "----",
            cryptoName: "----",
            sentiment: "----",
          },
          {
            id: -2,
            rank: "----",
            cryptoName: "----",
            sentiment: "----",
          },
        ];
      } else if (sortedQueriedData.length % 3 === 2) {
        sortedQueriedData = [
          ...sortedQueriedData,
          {
            id: -1,
            rank: "----",
            cryptoName: "----",
            sentiment: "----",
          },
        ];
      }

      setData(sortedQueriedData);
    })();
  }, []);

  return (
    <StyledWrapper className={props.className}>
      <TableWrapper>
        <StyledTable
          columns={columns as any[]}
          data={data}
          pagination
          paginationPerPage={3}
          paginationRowsPerPageOptions={[]}
          paginationComponentOptions={{ noRowsPerPage: true }}
          paginationIconNext={
            <PaginationButtonWrapper>
              <FontAwesomeIcon icon={faAngleRight} />
            </PaginationButtonWrapper>
          }
          paginationIconPrevious={
            <PaginationButtonWrapper>
              <FontAwesomeIcon icon={faAngleLeft} />
            </PaginationButtonWrapper>
          }
          paginationIconFirstPage={
            <PaginationButtonWrapper>
              <FontAwesomeIcon icon={faAnglesLeft} />
            </PaginationButtonWrapper>
          }
          paginationIconLastPage={
            <PaginationButtonWrapper>
              <FontAwesomeIcon icon={faAnglesRight} />
            </PaginationButtonWrapper>
          }
          noDataComponent={
            <NoDataMessage>No trend data to display.</NoDataMessage>
          }
          onChangePage={(data) => {
            console.log(data);
          }}
        />
      </TableWrapper>
    </StyledWrapper>
  );
}

const StyledWrapper = styled.div`
  background-color: ${MAIN_COMPONENT_COLOR};
  border-radius: 10px;
  color: ${MAIN_COLOR};
  display: flex;
  justify-content: space-around;
  align-items: center;
  /* font-size: calc(0.85vh + 0.85vw); */

  /* @media screen and (max-width: 700px) {
    font-size: calc(1.4vh + 1.4vw);
    text-align: center;
    padding: 1.5%;
  } */
`;

const TableWrapper = styled.div`
  width: 100%;
  .rdt_Pagination {
    background-color: ${MAIN_COMPONENT_COLOR};
    color: ${MAIN_TEXT_COLOR};
    border-radius: 0px 0px 10px 10px;
  }
  display: flex;
  flex-direction: column;
  justify-content: space-between !important;
`;

const StyledTable = styled(DataTable)`
  .rdt_Table {
    background-color: ${MAIN_COMPONENT_COLOR};
    border-radius: 10px;
  }
  .rdt_TableHeadRow {
    background-color: ${MAIN_COMPONENT_COLOR};
    border-radius: 10px 10px 0px 0px;
    color: ${MAIN_TEXT_COLOR};
    font-weight: bold;
  }
  .rdt_TableRow {
    background-color: ${MAIN_COMPONENT_COLOR};
    color: ${MAIN_TEXT_COLOR};
  }
`;

const PaginationButtonWrapper = styled.div`
  color: ${MAIN_TEXT_COLOR};
`;

const Link = styled.span`
  color: ${LINK_COLOR};
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
`;

const GoodSentimentGauge = styled(ProgressBar)`
  .progress-bar {
    background-color: ${GOOD_SENTIMENT_GAUGE_COLOR};
    color: ${MAIN_TEXT_COLOR};
  }
`;
const MidSentimentGauge = styled(ProgressBar)`
  .progress-bar {
    background-color: ${MID_SENTIMENT_GAUGE_COLOR};
    color: ${SECONDARY_TEXT_COLOR};
  }
`;
const BadSentimentGauge = styled(ProgressBar)`
  .progress-bar {
    background-color: ${BAD_SENTIMENT_GAUGE_COLOR};
    color: ${MAIN_TEXT_COLOR};
  }
`;
const SentimentGaugeWrapper = styled.div`
  width: 100%;
  .progress {
    background-color: ${INPUT_COLOR};
  }
`;
const NoDataMessage = styled.div`
  background-color: ${MAIN_COMPONENT_COLOR};
  color: ${MAIN_TEXT_COLOR};
  width: 100%;
  text-align: center;
`;
