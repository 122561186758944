import { Modal, InputGroup } from "react-bootstrap";
import { Search } from "react-router-dom";
import styled from "styled-components";
import {
  INPUT_COLOR,
  MAIN_COLOR,
  MAIN_COLOR_ON_HOVER,
  MAIN_COMPONENT_COLOR,
  MAIN_TEXT_COLOR,
} from "../constants/colors";
import MainButton from "./MainButton";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import SecondaryButton from "./SecondaryButton";

export function MainModal(props: {
  children?: React.ReactNode;
  title?: React.ReactNode | string;
  show: boolean;
  handleClose: Function;
}) {
  return (
    <StyledModal show={props.show} onHide={props.handleClose}>
      <Header>
        <Modal.Title>{props.title}</Modal.Title>
        <CloseButton onClick={props.handleClose}>
          <FontAwesomeIcon icon={faXmark} />
        </CloseButton>
      </Header>
      <Body>{props.children}</Body>
    </StyledModal>
  );
}

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: ${MAIN_COLOR};
  }
`;

const Body = styled(Modal.Body)`
  overflow-y: auto !important;
  max-height: 500px;
  color: ${MAIN_TEXT_COLOR};
`;

const Header = styled(Modal.Header)`
  color: ${MAIN_TEXT_COLOR};
  border-color: ${INPUT_COLOR};
`;

const CloseButton = styled(SecondaryButton)`
  /* width: 10%;
  height: 5%; */
`;
