import styled from "styled-components";
import {
  INPUT_COLOR,
  MAIN_COLOR,
  MAIN_COMPONENT_COLOR,
  SECONDARY_INPUT_COLOR,
} from "../constants/colors";
import SwapBox from "./SwapBox";
import MainButton from "./MainButton";
import { useContext, useEffect, useRef, useState } from "react";
import { token } from "../constants/types";
import {
  TokenTypes,
  getPrice,
  approve,
  getAllowance,
  isConnected,
  swap,
} from "../scripts/web3/frontend_web3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGasPump, faRightLeft } from "@fortawesome/free-solid-svg-icons";
import { ETH_ADDRESS } from "../constants/ABI";
import { ENDPOINTS_ADDRESS } from "../constants/ip_address";
import { Spinner } from "react-bootstrap";
import { UserContext } from "..";
import { ErrorContext } from "../pages/PageWrapper";

const nullToken: token = {
  address: "",
  chainId: 0,
  decimals: 0,
  extensions: undefined,
  logoURI: "",
  name: "",
  symbol: "",
};
const ethereumToken: token = {
  address: ETH_ADDRESS,
  chainId: 1,
  decimals: 18,
  extensions: undefined,
  logoURI: "/resources/ethereum_logo.png",
  name: "Ethereum",
  symbol: "ETH",
};

export default function TradingBox(props: {
  className?: string;
  tokenListURL: string;
}) {
  const { loggedIn } = useContext(UserContext);
  const setError = useContext(ErrorContext);

  const [tokens, setTokens] = useState([] as token[]);
  const [buyToken, setBuyToken] = useState(ethereumToken);
  const [sellToken, setSellToken] = useState(nullToken);
  const [buyTokenText, setBuyTokenText] = useState("SELECT TOKEN");
  const [sellTokenText, setSellTokenText] = useState("SELECT TOKEN");
  const [buyTokenAmount, setBuyTokenAmount] = useState(0);
  const [sellTokenAmount, setSellTokenAmount] = useState(0);
  const [buyTokenAmountInput, setBuyTokenAmountInput] = useState("");
  const [sellTokenAmountInput, setSellTokenAmountInput] = useState("");
  const [buyTokenPrice, setBuyTokenPrice] = useState(0);
  const [sellTokenPrice, setSellTokenPrice] = useState(0);
  const [gasPrice, setGasPrice] = useState(0);
  const [swapDisabled, setSwapDisabled] = useState(true);
  const [spinnerHidden, setSpinnerHidden] = useState(true);

  //Used to hold the updated amount values across rerenders in a global memory.
  // These values are updated upon every time the token amounts changes.
  // useRef allows for the value to be stores globally, accessible and the same across ALL renders
  // (even if a given render is still running a loop)
  // if the value is different than the state value that is stored locally for a given render in memory,
  // then no more recursive price requests will be made.
  const buyTokenAmountRef = useRef(0);
  const sellTokenAmountRef = useRef(0);
  const buyTokenRef = useRef(nullToken);
  const sellTokenRef = useRef(nullToken);

  useEffect(() => {
    (async () => {
      const tokens = await fetch(`${ENDPOINTS_ADDRESS}/tokens`).then((data) =>
        data.json()
      );
      setTokens(tokens);
    })();
  }, []);

  useEffect(() => {
    if (buyToken.symbol) setBuyTokenText(buyToken.symbol);
    if (sellToken.symbol) setSellTokenText(sellToken.symbol);
  }, [buyToken, sellToken]);

  useEffect(() => {
    buyTokenAmountRef.current = buyTokenAmount;
    buyTokenRef.current = buyToken;
    sellTokenRef.current = sellToken;
    let retry: NodeJS.Timeout;
    const requestPrice = async () => {
      console.log(
        TokenTypes.buy,
        buyToken.symbol,
        sellToken.symbol,
        sellToken.decimals,
        buyToken.decimals,
        buyTokenAmount
      );
      if (buyTokenAmount) {
        const priceObj = await getPrice(
          TokenTypes.buy,
          buyToken.address,
          sellToken.address,
          sellToken.decimals,
          buyToken.decimals,
          buyTokenAmount
        );
        if (!priceObj.failed) {
          setSellTokenPrice(priceObj.price);
          setGasPrice(priceObj.gasEstimate);
          setSellTokenAmountInput("");
          setTimeout(async () => {
            if ((await isConnected()) && loggedIn) {
              setSwapDisabled(false);
            }
            setSpinnerHidden(true);
          }, 2000);
        } else {
          setSpinnerHidden(false);
          setSwapDisabled(true);
          setSellTokenPrice(-1);
          setGasPrice(0);
          setError(priceObj.message);
          retry = setTimeout(() => {
            clearTimeout(retry);
            if (
              buyTokenAmount === buyTokenAmountRef.current &&
              buyToken === buyTokenRef.current &&
              sellToken === sellTokenRef.current
            )
              requestPrice();
          }, 2000);
        }
      }
    };
    requestPrice();
  }, [buyTokenAmount, buyToken, sellToken, loggedIn]);

  useEffect(() => {
    sellTokenAmountRef.current = sellTokenAmount;
    buyTokenRef.current = buyToken;
    sellTokenRef.current = sellToken;
    let retry: NodeJS.Timeout;
    const requestPrice = async () => {
      console.log(
        TokenTypes.sell,
        buyToken.symbol,
        sellToken.symbol,
        sellToken.decimals,
        buyToken.decimals,
        sellTokenAmount
      );
      if (sellTokenAmount) {
        const priceObj = await getPrice(
          TokenTypes.sell,
          buyToken.address,
          sellToken.address,
          sellToken.decimals,
          buyToken.decimals,
          sellTokenAmount
        );
        if (!priceObj.failed) {
          setBuyTokenPrice(priceObj.price);
          setGasPrice(priceObj.gasEstimate);
          setBuyTokenAmountInput("");
          setTimeout(async () => {
            if ((await isConnected()) && loggedIn) {
              setSwapDisabled(false);
            }
            setSpinnerHidden(true);
          }, 2000);
        } else {
          setSpinnerHidden(false);
          setSwapDisabled(true);
          setBuyTokenPrice(-1);
          setGasPrice(0);
          setError(priceObj.message);
          retry = setTimeout(() => {
            clearTimeout(retry);
            if (
              sellTokenAmount === sellTokenAmountRef.current &&
              buyToken === buyTokenRef.current &&
              sellToken === sellTokenRef.current
            )
              requestPrice();
          }, 2000);
        }
      }
    };
    requestPrice();
  }, [sellTokenAmount, sellToken, buyToken, loggedIn]);

  return (
    <Wrapper className={props.className + " rounded-3"}>
      <SwapBoxes>
        <SwapBox
          setSelectedToken={setBuyToken}
          buttonText={buyTokenText}
          tokens={tokens}
          setTokenAmount={setBuyTokenAmount}
          icon={buyToken.logoURI}
          setAmountInput={setBuyTokenAmountInput}
          amountInput={buyTokenAmountInput}
          tokenPrice={buyTokenPrice}
        />
        <SwitchButton
          onClick={() => {
            const timeOutId = setTimeout(() => {
              if (
                buyToken.address !== nullToken.address &&
                sellToken.address !== nullToken.address
              ) {
                const temp = buyToken;
                setBuyToken(sellToken);
                setSellToken(temp);
              }
            }, 300);
            return () => clearTimeout(timeOutId);
          }}
        >
          <SwapIcon icon={faRightLeft} rotation={90} />
        </SwitchButton>
        <SwapBox
          setSelectedToken={setSellToken}
          buttonText={sellTokenText}
          tokens={tokens}
          setTokenAmount={setSellTokenAmount}
          icon={sellToken.logoURI}
          setAmountInput={setSellTokenAmountInput}
          amountInput={sellTokenAmountInput}
          tokenPrice={sellTokenPrice}
        />
      </SwapBoxes>
      <GasWrapper>
        <Gas className="rounded-2">
          <FontAwesomeIcon icon={faGasPump} />
          <GasText>{gasPrice}</GasText>
        </Gas>
      </GasWrapper>
      <SwapWrapper>
        <PriceSpinner hidden={spinnerHidden} />
        <SwapButton
          buyTokenAddress={buyToken.address}
          buyAmount={buyTokenAmount ? buyTokenAmount : buyTokenPrice}
          sellTokenAddress={sellToken.address}
          buyDecimals={buyToken.decimals}
          disabled={swapDisabled}
          setDisabled={setSwapDisabled}
          setSpinnerHidden={setSpinnerHidden}
          loggedIn={loggedIn}
        />
      </SwapWrapper>
    </Wrapper>
  );
}

function SwapButton(props: {
  buyTokenAddress: string;
  sellTokenAddress: string;
  buyDecimals: number;
  buyAmount: number;
  disabled: boolean;
  setDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  setSpinnerHidden: React.Dispatch<React.SetStateAction<boolean>>;
  loggedIn: boolean;
}) {
  const setError = useContext(ErrorContext);

  const [buttonText, setButtonText] = useState("SWAP");

  const approveOnClick = async () => {
    props.setDisabled(true);
    props.setSpinnerHidden(false);

    const success = await approve(
      props.buyTokenAddress,
      props.buyAmount,
      props.buyDecimals
    ).finally(() => {
      props.setDisabled(false);
      props.setSpinnerHidden(true);
    });
    console.log(success);

    if (success) {
      setButtonText("SWAP");
      setOnClick({ function: swapOnClick });
    }
  };
  const swapOnClick = async () => {
    const quote = await swap(
      props.buyTokenAddress,
      props.sellTokenAddress,
      props.buyAmount,
      props.buyDecimals
    );

    if (quote?.failed) {
      setError(quote.message);
    }
  };

  const [onClick, setOnClick] = useState({ function: approveOnClick });

  useEffect(() => {
    (async () => {
      if (props.buyTokenAddress && props.buyTokenAddress !== ETH_ADDRESS) {
        const allowance = await getAllowance(props.buyTokenAddress);
        if ((allowance as number) < props.buyAmount * 10 ** props.buyDecimals) {
          setButtonText("APPROVE");
          setOnClick({ function: approveOnClick });
          // props.setDisabled(false);
        } else {
          setButtonText("SWAP");
          // if (!props.buyAmount || !(await isConnected())) {
          //   props.setDisabled(true);
          // } else props.setDisabled(false);
          setOnClick({ function: swapOnClick });
        }
      } else if (
        props.buyTokenAddress === ETH_ADDRESS &&
        props.buyAmount &&
        (await isConnected()) &&
        props.loggedIn
      ) {
        props.setDisabled(false);
        setButtonText("SWAP");
        setOnClick({ function: swapOnClick });
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.buyAmount, props.buyTokenAddress, props.loggedIn]);

  return (
    <>
      <SwapButtonStyled
        width={"30%"}
        onClick={onClick.function}
        disabled={props.disabled}
      >
        {buttonText}
      </SwapButtonStyled>
    </>
  );
}

const Wrapper = styled.div`
  width: 30vw;
  height: 42vh;
  background-color: ${MAIN_COMPONENT_COLOR};
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 700px) {
    width: 80vw;
    height: 42vh;
  }
`;
const SwapBoxes = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  padding-top: 5%;
`;
const GasWrapper = styled.div`
  display: flex;
  height: 10%;
  flex-direction: row-reverse;
`;
const Gas = styled.span`
  width: 30%;
  height: 80%;
  background-color: ${INPUT_COLOR};
  color: ${MAIN_COLOR};
  margin-right: 6.5%;
  margin-top: 2%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1%;
  padding-right: 1%;

  @media screen and (max-width: 700px) {
    width: 30%;
    height: 100%;
  }
`;
const GasText = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  height: 100%;
  color: ${MAIN_COLOR};
  font-size: calc(0.8vw + 0.8vh);

  @media screen and (max-width: 700px) {
    font-size: calc(1.5vw + 1.5vh);
    margin-top: 0.5vh;
  }
`;
const SwapWrapper = styled.div`
  height: 30%;
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* padding-left: 40; */
`;

const SwapIcon = styled(FontAwesomeIcon)`
  width: 85%;
  height: 85%;
  margin-top: 10%;
`;

const PriceSpinner = styled(Spinner)`
  color: ${SECONDARY_INPUT_COLOR};
  position: absolute;
  float: left;
  right: 48%;

  @media screen and (max-width: 700px) {
    /* width: 50vw !important; */
    right: 12%;
  }
`;

const SwapButtonStyled = styled(MainButton)`
  margin-left: auto;
  margin-right: auto;

  @media screen and (max-width: 700px) {
    width: 50vw !important;
  }
`;

const SwitchButton = styled(MainButton)`
  width: 4vw;
  height: 7vh;
  margin-top: 5%;

  @media screen and (max-width: 700px) {
    width: 15vw;
    height: 7vh;
    margin-top: 0;
  }
`;
