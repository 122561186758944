// export const MAIN_COLOR = "#0C134F";
// export const MAIN_COLOR_ON_HOVER = "#111a6c";
// export const MAIN_COMPONENT_COLOR = "#1D267D";
// export const INPUT_COLOR = "#D4ADFC";
// export const MAIN_TEXT_COLOR = "#5C469C";
// export const BANNER_COLOR = "#080c32";
// export const DISABLED_INPUT_COLOR = "#9B7DB9";

export const MAIN_COLOR = "#576CBC";
export const MAIN_COLOR_ON_HOVER = "#6D7FC5";
export const MAIN_COLOR_DISABLED = "#3A4C92";
export const MAIN_COMPONENT_COLOR = "#19376D";
export const INPUT_COLOR = "#DBE6FD";
export const SECONDARY_INPUT_COLOR = "#576CBC";
export const MAIN_TEXT_COLOR = "#DBE6FD";
export const SECONDARY_TEXT_COLOR = "#142B57";
export const BANNER_COLOR = "#142B57";
export const DISABLED_INPUT_COLOR = "#697590";
export const ACTIVE_COLOR = "#DBE6FD";
export const LINK_COLOR = "#DBE6FD";
export const GOOD_SENTIMENT_GAUGE_COLOR = "#09BC8A";
export const MID_SENTIMENT_GAUGE_COLOR = "#F2CD5D";
export const BAD_SENTIMENT_GAUGE_COLOR = "#D7263D";
