import styled from "styled-components";
import ChatBox from "../components/ChatBox";
import Banner from "../components/Banner";
import { Footer } from "../components/Footer";
import MediaQuery, { useMediaQuery } from "react-responsive";
import { Alert, Collapse } from "react-bootstrap";
import { SECONDARY_INPUT_COLOR } from "../constants/colors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTriangleExclamation } from "@fortawesome/free-solid-svg-icons";
import { createContext, useContext, useEffect, useState } from "react";
import { MainModal } from "../components/MainModal";
import MainButton from "../components/MainButton";
import SecondaryButton from "../components/SecondaryButton";
import { UserContext } from "..";
import { isConnected } from "../scripts/web3/frontend_web3";

export const ErrorContext = createContext({} as Function);

export function PageTemplate(props: { children: JSX.Element }) {
  const [showError, setShowError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [showMobileWarning, setShowMobileWarning] = useState(false);

  const isMobile = useMediaQuery({ maxWidth: 700 });
  const { loggedIn } = useContext(UserContext);
  console.log(loggedIn);

  useEffect(() => {
    (async () => {
      if (!loggedIn && !(await isConnected()) && isMobile)
        setShowMobileWarning(true);
    })();
  }, []);

  let timeout: NodeJS.Timeout;
  function setError(message: string) {
    setShowError(true);
    setErrorMessage(message);

    clearTimeout(timeout);
    timeout = setTimeout(() => {
      setShowError(false);
      setErrorMessage("");
    }, 7000);
  }

  return (
    <ErrorContext.Provider value={setError}>
      <Wrapper>
        <Banner />
        <ChatAndContentWrapper>
          <ContentWrapper>{props.children}</ContentWrapper>
          <MediaQuery minWidth={700}>
            <StyledChatBox className="rounded-3" />
          </MediaQuery>
        </ChatAndContentWrapper>
        <ErrorWrapper>
          <Collapse in={showError} dimension={"width"}>
            <div>
              <GenericError variant="warning">
                <FontAwesomeIcon icon={faTriangleExclamation} /> ERROR:{" "}
                <ErrorMessage>{errorMessage}</ErrorMessage>
              </GenericError>
            </div>
          </Collapse>
        </ErrorWrapper>
        <MainModal
          show={showMobileWarning}
          handleClose={() => {
            setShowMobileWarning(false);
          }}
          title={
            <div>
              <FontAwesomeIcon icon={faTriangleExclamation} /> WARNING{" "}
              <FontAwesomeIcon icon={faTriangleExclamation} />
            </div>
          }
        >
          The Metamask mobile app is very unstable with the current version of
          Chatswap (and may be unstable in general). It is highly recommended to
          do any trading on a desktop computer with the Metamask browser
          extension. You can still use the app to log in so you can chat,
          although some bugs may occur. Remember Chatswap is an <b>ALPHA</b>{" "}
          product!
          <UnderstandButtonWrapper>
            <SecondaryButton
              onClick={() => {
                setShowMobileWarning(false);
              }}
            >
              I understand
            </SecondaryButton>
          </UnderstandButtonWrapper>
        </MainModal>
        <Footer />
      </Wrapper>
    </ErrorContext.Provider>
  );
}

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const ChatAndContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 100%;
`;
const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
`;

const StyledChatBox = styled(ChatBox)`
  max-width: 25%;
  min-width: 25%;
  height: 100%;
  max-height: 93vh;
  margin-top: 10px;
  padding-top: 50px;
`;

const GenericError = styled(Alert)`
  background-color: ${SECONDARY_INPUT_COLOR};
  border-color: ${SECONDARY_INPUT_COLOR};
  color: black;
  white-space: nowrap;
`;

const ErrorWrapper = styled.div`
  position: absolute;
  bottom: 5vh;
  width: 100%;
  display: flex;
  justify-content: center;
`;

const ErrorMessage = styled.span`
  font-size: calc(0.6vw + 0.6vh);
  font-style: italic;
  background-color: rgba(0, 0, 0, 0.1);
`;

const UnderstandButtonWrapper = styled.div`
  margin-top: 10%;
  display: flex;
  justify-content: center;
`;
