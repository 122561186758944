import { BaseSyntheticEvent, useContext, useEffect, useState } from "react";
import styled from "styled-components";
import {
  ACTIVE_COLOR,
  BANNER_COLOR,
  INPUT_COLOR,
  MAIN_COLOR,
  MAIN_COLOR_ON_HOVER,
  MAIN_TEXT_COLOR,
} from "../constants/colors";
import MainButton from "./MainButton";
import {
  forceSignature,
  getAccount,
  getCookie,
  getSignature,
  isConnected,
} from "../scripts/web3/frontend_web3";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCommentDots,
  faRightToBracket,
  faWallet,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { Badge, Button, Form, InputGroup, Offcanvas } from "react-bootstrap";
import type { User } from "@prisma/client";
import { useNavigate } from "react-router-dom";
import { UserButton } from "./UserButton";
import { UserContext } from "..";
import logo from "../resources/logo.png";
import { ENDPOINTS_ADDRESS } from "../constants/ip_address";
import MediaQuery, { useMediaQuery } from "react-responsive";
import ChatBox from "./ChatBox";
import { MainModal } from "./MainModal";
import SecondaryButton from "./SecondaryButton";

export default function Banner() {
  const [account, setAccount] = useState("");
  const [loginFormHidden, setLoginFormHidden] = useState(true);
  const [usernameInput, setUsernameInput] = useState("");
  const [loginButtonHidden, setLoginButtonHidden] = useState(false);
  const [userButtonHidden, setUserButtonHidden] = useState(true);
  const [signature, setSignature] = useState("");
  const [clickedLoginFlag, setClickedLoginFlag] = useState(false);
  const [forcedSignature, setForcedSignature] = useState(false);
  const [showMobileChatOverlay, setShowMobileChatOverlay] = useState(false);
  const [showMobileLoginModal, setShowMobileLoginModal] = useState(false);

  const navigate = useNavigate();
  const { user, setUser, loggedIn, setLoggedIn } = useContext(UserContext);

  async function login() {
    setClickedLoginFlag(true);
    const addresses = (await getAccount()) as string[];
    if (await isConnected()) {
      setAccount(addresses[0]);
      if (!getCookie("signature")) {
        try {
          setSignature(await getSignature(account));
        } catch (e) {
          console.log(e);
        }
      } else {
        setSignature(await getSignature(account));
        const user = await fetch(
          `${ENDPOINTS_ADDRESS}/users/${account}/${signature}`
        ).then((result) => result.json());

        if (!user) {
          setSignature(await forceSignature(account));
          setForcedSignature(true);
          setLoginButtonHidden(true);
          setLoginFormHidden(false);
          setShowMobileLoginModal(true);
        } else {
          setLoginButtonHidden(true);
          setLoginFormHidden(true);
          setUser(user);
          setUserButtonHidden(false);
        }
      }
    }
  }
  async function createUser() {
    if (usernameInput) {
      console.log(
        await fetch(
          `${ENDPOINTS_ADDRESS}/createUser/${account}/${usernameInput}/${signature}`,
          { method: "POST" }
        )
      );
      const user: User = await fetch(
        `${ENDPOINTS_ADDRESS}/users/${account}/${signature}`
      ).then((result) => result.json());
      setLoginButtonHidden(true);
      setLoginFormHidden(true);
      setUser(user);
      setUserButtonHidden(false);
      setShowMobileLoginModal(false);
    }
  }

  useEffect(() => {
    (async () => {
      if (await isConnected()) {
        setAccount(((await isConnected()) as string[])[0]);
        if (!getCookie("signature")) {
          if (!clickedLoginFlag) return;
          try {
            setSignature(await getSignature(account));
          } catch (e) {
            console.log(e);
          }
        } else {
          setSignature(await getSignature(account));
          const user = await fetch(
            `${ENDPOINTS_ADDRESS}/users/${account}/${signature}`
          ).then((result) => result.json());
          if (!user) {
            if (!clickedLoginFlag || forcedSignature) return;
            setSignature(await forceSignature(account));
          }
        }
        const user = await fetch(
          `${ENDPOINTS_ADDRESS}/users/${account}/${signature}`
        ).then((result) => result.json());

        if (user) {
          setLoginButtonHidden(true);
          setLoginFormHidden(true);
          setUser(user);
          setUserButtonHidden(false);
        } else {
          setLoginButtonHidden(true);
          setLoginFormHidden(false);
          setShowMobileLoginModal(true);
        }
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account, signature]);

  useEffect(() => {
    if (user.id) setLoggedIn(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Wrapper>
      <TitleWrapper>
        <Logo
          src={"/resources/logo.png"}
          onClick={() => {
            navigate("/");
          }}
        />
        <StyledBadge>
          ALPHA <VersionText>v3.0</VersionText>
        </StyledBadge>
      </TitleWrapper>
      <LoginWrapper>
        <MediaQuery minWidth={700}>
          <LoginFormWrapper>
            <InputGroup hidden={loginFormHidden}>
              <StyledInput
                placeholder="Enter username"
                onChange={(e: BaseSyntheticEvent) =>
                  setUsernameInput(e.target.value)
                }
              />
              <LoginButton onClick={createUser}>
                <FontAwesomeIcon icon={faRightToBracket} />
              </LoginButton>
            </InputGroup>
          </LoginFormWrapper>
        </MediaQuery>
        <MediaQuery maxWidth={700}>
          <MainModal
            show={showMobileLoginModal}
            handleClose={() => {
              setShowMobileLoginModal(false);
            }}
            title="Choose a username"
          >
            <InputGroup>
              <StyledInput
                placeholder="Enter username"
                onChange={(e: BaseSyntheticEvent) =>
                  setUsernameInput(e.target.value)
                }
              />
              <ModalLoginButton onClick={createUser}>
                <FontAwesomeIcon icon={faRightToBracket} />
              </ModalLoginButton>
            </InputGroup>
          </MainModal>
        </MediaQuery>
        <LoginButton onClick={login} hidden={loginButtonHidden}>
          <WalletIcon icon={faWallet} /> LOGIN
        </LoginButton>
        <UserButton hidden={userButtonHidden} />
      </LoginWrapper>
      <MediaQuery maxWidth={700}>
        <MainButton
          onClick={() => {
            setShowMobileChatOverlay(true);
          }}
        >
          <FontAwesomeIcon icon={faCommentDots} />
        </MainButton>
        <ChatOffcanvas
          show={showMobileChatOverlay}
          onHide={() => {
            setShowMobileChatOverlay(false);
          }}
          placement={"end"}
        >
          <CloseChatButton
            onClick={() => {
              setShowMobileChatOverlay(false);
            }}
          >
            <FontAwesomeIcon icon={faXmark} />
          </CloseChatButton>
          <StyledChatBox />
        </ChatOffcanvas>
      </MediaQuery>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  font-weight: bolder;
  color: ${MAIN_TEXT_COLOR};
  font-size: 30px;
  position: absolute;
  background-color: ${BANNER_COLOR};
  width: 100%;
  height: 7%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 1%;
  padding-right: 1%;
  z-index: 999;
`;

const StyledBadge = styled(Badge)`
  font-size: calc(0.5vw + 0.5vh);
  margin-left: 5%;
  background-color: ${MAIN_COLOR} !important;
  color: ${MAIN_TEXT_COLOR};

  @media screen and (max-width: 700px) {
    font-size: calc(0.7vw + 0.7vh);
  }
`;
const VersionText = styled.span`
  font-size: calc(0.4vw + 0.4vh);

  @media screen and (max-width: 700px) {
    font-size: calc(0.6vw + 0.6vh);
  }
`;
const Logo = styled.img`
  /* margin-top: 2%; */
  width: 12vw;
  &:hover {
    cursor: pointer;
  }

  @media screen and (max-width: 700px) {
    width: 40vw;
    margin-left: 2vw;
  }
`;
const TitleWrapper = styled.span`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
`;

const WalletIcon = styled(FontAwesomeIcon)`
  margin-right: 10px;
`;

const StyledInput = styled(Form.Control)`
  background-color: ${INPUT_COLOR};
  border-color: ${INPUT_COLOR};
  form {
    overflow: hidden;
  }
  input {
    float: right;
    clear: both;
  }
  height: 4vh;
  font-size: 13px;
`;

const LoginFormWrapper = styled.span`
  height: 4vh !important;
`;

const LoginWrapper = styled.span`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 40%;
`;

const LoginButton = styled(MainButton)`
  height: 4vh;
  font-size: 13px;
  display: flex;
  align-items: center;

  @media screen and (max-width: 700px) {
    margin-right: 5%;
  }
`;

const ModalLoginButton = styled(SecondaryButton)`
  height: 4vh;
  font-size: 13px;
  display: flex;
  align-items: center;
`;

const StyledChatBox = styled(ChatBox)`
  width: 100%;
  height: 95%;
  border-bottom-left-radius: 10px;
`;

const ChatOffcanvas = styled(Offcanvas)`
  background-color: transparent;
`;

const CloseChatButton = styled(MainButton)`
  position: absolute;
  right: 1%;
  top: 1%;
  width: 8%;
  height: 4%;
  display: flex;
  justify-content: center;
  align-items: center;
`;
