export const changelog = [
  {
    Heading: "Alpha v1.1",
    Bullets: [
      "Added new changelog page (you're looking at it right now).",
      "Starting recording chats to database.",
      "Latest chats that occured before loading the web app now are returned from the database to the user when loading the page, showing the last 100 chats. This amount may be changed depending on the popularity of Chatswap.",
    ],
  },
  {
    Heading: "Alpha v2.0",
    Bullets: [
      "Huge changes!",
      "Created placeholder icon for tokens without an icon.",
      "Proxied the 0x API within chatswap API server.",
      "Debugged token pricing not loading sometimes and implemented request retries with loading spinner.",
      "Tokens are now sorted in the token selection modal, but Ethereum stays at the very top.",
      "Debugged trades not always being sent into chat.",
      "Swap button now will change back after to swapping after approval is successful.",
      "Completely reoptimized the entire web app for different screen sizes and mobile users.",
      "Other bug fixes.",
    ],
  },
  {
    Heading: "Alpha v2.1",
    Bullets: [
      "Bug fixes.",
      "Optimized mobile username input by placing within a modal.",
      "Implemented error alert to give users information on certain problems they may encounter if they encounter them.",
    ],
  },
  {
    Heading: "Alpha v2.2",
    Bullets: [
      "Added Metamask trading warning for mobile users.",
      "Fixed bug of long usernames displacing elements on mobile.",
    ],
  },
  {
    Heading: "Alpha v2.3",
    Bullets: [
      "Fixed bug where spinner persisted if user is not logged in.",
      "Fixed bug where for certain swapping errors the error alert did not show.",
    ],
  },
  {
    Heading: "Alpha v3.0",
    Bullets: [
      "The AI Trends table is here! The trends will now be populated regularly in the table based upon what is discussed in the main chat.",
    ],
  },
];
