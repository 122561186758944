import styled from "styled-components";
import {
  MAIN_COLOR,
  MAIN_COLOR_DISABLED,
  MAIN_COLOR_ON_HOVER,
  MAIN_COMPONENT_COLOR,
  MAIN_TEXT_COLOR,
} from "../constants/colors";
import { Button } from "react-bootstrap";

export default function SecondaryButton(props: {
  width?: string;
  className?: string;
  disabled?: boolean;
  children?: React.ReactNode;
  fontSize?: string;
  hidden?: boolean;
  onClick: Function;
}) {
  const StyledSecondaryButton = styled(Button)`
    &:hover {
      background-color: ${MAIN_TEXT_COLOR};
      border-color: ${MAIN_TEXT_COLOR};
    }
    &:active {
      background-color: ${MAIN_COMPONENT_COLOR} !important;
      border-color: ${MAIN_COMPONENT_COLOR} !important;
    }
    &:disabled {
      border: 0;
      background-color: ${MAIN_COLOR_DISABLED};
    }
    width: ${props.width};
    background-color: ${MAIN_COMPONENT_COLOR};
    color: ${MAIN_TEXT_COLOR};
    border-color: ${MAIN_COMPONENT_COLOR};
  `;
  return (
    <StyledSecondaryButton
      className={props.className}
      onClick={props.onClick}
      disabled={props.disabled}
      hidden={props.hidden}
    >
      {props.children}
    </StyledSecondaryButton>
  );
}
